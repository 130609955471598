import logo from './logo.svg';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <h3>
          {/* Edit <code>src/App.js</code> and save to reload. */}
          Welcome to Leo Phan's website
        </h3>
        
        <div class="badge-base LI-profile-badge" data-locale="en_US" data-size="large" data-theme="dark" data-type="HORIZONTAL" data-vanity="leopha" data-version="v1">
          <a class="badge-base__link LI-simple-link" href="https://www.linkedin.com/in/leopha?trk=profile-badge">
          </a>
        </div>
        {/* <a
          className="App-link"
          href="https://www.linkedin.com/in/qphan02/"
          target="_blank"
          rel="noopener noreferrer"
        >
          Go to Leo Phan's Linkedin
        </a> */}
      </header>
    </div>
  );
}

export default App;
